.service-card {
    display: flex;
    border: 1px solid #000;
    max-width: 400px;
    margin: 20px auto;
    height: 300px;
    perspective: 1000px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition pour l'effet de zoom et ombre */
}

/* Effet de zoom au survol */
.service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.no-border{
    border: 0px;
}

.icon-section {
    background-color: #fff;
    padding: 20px;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-examples img {
    width: 40px;
    height: 40px;
    margin: 10px 5px; /* Espacement amélioré entre les icônes */
}

.description-section {
    background-color: #000; /* Couleur vert foncé */
    color: #fff;
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description-section h3 {
    font-size: 1.2rem; /* Augmenter la taille du titre pour un meilleur contraste */
    font-weight: bold;
    margin-bottom: 10px; /* Espacement réduit sous le titre */
}

.description-section p {
    font-size: 1rem;
    margin-bottom: 15px; /* Augmenter l'espacement sous la description */
}

.read-more {
    background-color: #4eaf46;
    color: #FFF;
    border: none;
    padding: 12px 24px; /* Plus de padding pour un bouton plus visible */
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 10px; /* Espacement entre la description et le bouton */
}

.read-more:hover {
    background-color: #52ba49;
}

/* Carte contenant les deux faces */
.service-card.flipped .card-front {
    transform: rotateY(180deg);
}

.service-card.flipped .card-back {
    transform: rotateY(0deg);
}

.card-front,
.card-back {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    backface-visibility: hidden;
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s;
}

/* Face avant */
.card-front {
    display: flex;
    transform: rotateY(0deg);
    flex-direction: row;
}

.card-back {
    transform: rotateY(180deg);
    background-color: #4eaf46;
    color: #fff;
    display: flex;
    padding: 20px;
}

.details-section {
    text-align: center;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details-section h3 {
    margin-bottom: 15px; /* Espacement sous le titre */
    font-size: 1.5rem;
}

.details-section p {
    font-size: 1rem;
    margin-bottom: 30px; /* Augmenter l'espacement sous le texte */
}

.go-back {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
}

.go-back:hover {
    background-color: #161616;
}