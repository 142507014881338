

@media (max-width: 1155px) {
  #contact{
      height: auto;
  }
 
}

#contact p{
  text-align: center;
}
.contact-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 28%;
  margin-inline: 5%;
}

/* Partie introduction du contact */
.contact-intro h2 {
  font-size: 2.5rem;
  color: #2e7d32;
  /* couleur principale */
  margin-bottom: 10px;
}

.contact-intro p {
  font-size: 1.1rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Partie du corps du contact */
.contact-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 72%;
}

/* Informations de contact */
.contact-info {
  background-color: #333;
  /* fond noir */
  color: white;
  padding: 30px;
  display: flex;
  /* Utilisation de flexbox */
  flex-wrap: wrap;
  /* Permet aux éléments de se repositionner sur plusieurs lignes si nécessaire */
  gap: 15px;
  /* Espacement entre les cases */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Ombre légère */
  flex: 1 1 45%;
  /* Prend 45% de la largeur sur écran large */
  text-align: left;
  padding-left: 8%;
  padding-top: 8%;
}

/* Chaque info-box dans la flexbox */
.contact-info .info-box {
  display: flex;
    flex-direction: column;
    flex: 1 1 45%;

}

.info-box > div{
  display: flex;
    flex-direction: column;
    gap: 17px;
}

/* Icônes */
.contact-info .info-box i {
  font-size: 2rem;
  color: #d5a36a;
  /* couleur dorée pour les icônes */
  margin-bottom: 10px;
}

.info-box svg {
  width: 20px;
  height: 20px;
}

/* Titre de chaque info-box */
.contact-info .info-box h4 {
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 5px;
}

/* Texte de chaque info-box */
.contact-info .info-box p {
  font-size: 1rem;
  color: #ccc;
  margin: 0;
}

.info-box a {
  text-decoration: none
}

.info-box a:hover {
  text-decoration: underline
}

/* Formulaire de contact */
.contact-form {
  background-color: #ffffff;
  padding: 30px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Style des champs du formulaire */
.contact-form input,
.contact-form textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.contact-form textarea {
  resize: vertical;
}

.contact-form button {
  background-color: #d5a36a;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #b3825c;
}

@media (max-height: 950px) {
  #contact{
    height: auto;
}
.contact-intro{
  padding-block: 5%;
}
}


@media (max-width: 1155px) {
  #contact{
      height: auto;
  }
  .contact-intro{
    padding-block: 5%;
  }
  .contact-info {
    flex-direction: row;
    /* Passage en colonne sur les petits écrans */
  }

  .contact-body {
    flex-direction: column;
  }

  .contact-info {
    padding-top: 4%;
  }
  
}

@media (max-width: 588px) {
  
  .contact-info .info-box {
    flex: 1 1 100%;
    /* Chaque info-box prend toute la largeur sur mobile */
  }
  
}