.team-member {
  flex: 1 1 calc(25% - 30px); /* 4 membres par ligne avec des marges */
  max-width: calc(25% - 30px); /* Empêche les membres d'excéder leur place */
  min-width: 250px; /* Taille minimale pour garantir la lisibilité */
  box-sizing: border-box;
  background-color: #013220; /* Vert foncé élégant */
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.transition{
  transition: transform 0.3s, box-shadow 0.3s !important;
}

.team-member:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.team-member-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #d5a36a;
}

.team-member-info h3 {
  margin: 10px 0;
  font-size: 1.5rem;
  color: #ffffff; /* Blanc pour le texte */
}

.team-member-info p {
  font-size: 1rem;
  color: #d5a36a; /* Doré pour une cohérence visuelle */
  margin-bottom: 20px;
  min-height: 40px; /* Fixe une hauteur minimale pour les professions */
}

.team-member-contacts {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.contact-link {
  text-decoration: none;
}

.contact-icon {
  width: 25px;
  height: 25px;
  margin: 0 5px;
}