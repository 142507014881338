.footer {
    background-color: #2b2225;
    color: #d9dcde;
    padding: 20px;
  }

.footer p{
  margin-bottom: 0 !important;
  text-align: center !important;
}

.footer-link {
  color: #fff; /* Couleur de votre choix */
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.linkedin {
  color: #0073b1; /* LinkedIn blue */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}


