:root {
  --background: linear-gradient(to left, rgba(25, 138, 17, 0.85), rgba(0, 0, 0, 0.85));
  --background-two: #f7f7f7;
  --font-color: #fff;
  --font-family: 'Poppins', Arial, sans-serif;
  --green-color: #25b100;
  --dark-green-hover: #1a7a00;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(200px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  font-family: var(--font-family) !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

header {
  height: 100vh;
  text-align: center;
}

main{
  background: #f7f7f7;
}

.fond-header {
  position: absolute;
  background: var(--background);
  height: 100vh;
  width: 100%;
  z-index: -2;
}


.App {
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

main section {
  height: 100vh;
}

section h2 {
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.section h1,
.section h2 {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.section h1,
.section h2,
p,
a,
label,
li {
  color: var(--font-color);
}

p{
  text-align: justify;
}

.img-rupture {
  background-image: url('/public/img/autres/main.png');
  height: 40vh;
  background-size: cover;
  background-position: center;
}

@media (max-width: 900px) {
  .img-rupture {
    height: 20vh;
  }
}

.full-width-video {
  width: 100%;
  max-height: 40vh;
  object-fit: cover
}

iframe{
  width: 100%;
}