.join-us-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  perspective: 1000px;
}


.join-us-image{
  transform: scale(1.02) rotateY(20deg); /* Rotation de base */
  transform-style: preserve-3d;
  transition: transform 0.3s ease; /* Transition en cas de changement d'angle */
}

.join-us-image img {
  min-width: 400px;
  height: auto;
  border-radius: 8px;
}

.join-us-text {
  width: 80%;
  max-width: 600px;
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

#join > div{
  display: flex;
  flex-direction: column;
  height: 100%;
}

#join > div > h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  font-size: 1rem;
  color: #555 !important;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #30dd58;
  outline: none;
}

textarea {
  resize: vertical;
  max-height: 160px;
  min-height: 100px;
}

input::placeholder,
textarea::placeholder {
  color: #aaa;
  font-size: 0.9rem;
}

#upload-cv {
  display: none;
}

.btn-join {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4eaf46;
  color: white !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.btn-join:hover {
  background-color: #54b94a;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #555;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 1200px) {
    .join-us-image {
        display: none;
    }
}

@media (max-height: 950px) {

  .join-us-text{
    padding: 20px 30px !important;
  }
}

.join-us-text:hover {
  transform: scale(1.05) rotateY(5deg);
  transition: transform 0.3s ease;
}