#team {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#team p{
  text-align: center;
}

@media (max-height: 950px) {
  #team{
      height: auto;
  }

  .team-container{
    margin-block: 5%;
  }
  
}

@media (max-width: 1166px) {
  #team{
      height: auto;
  }
}

.team-section {
  padding: 40px 20px;
  text-align: center;
  color: #ffffff; /* Texte blanc pour contraster avec l'arrière-plan */
}

.team-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #d5a36a; /* Couleur dorée pour un aspect professionnel */
}

/* Conteneur des membres */
.team-container {
  display: flex;
  flex-wrap: wrap; /* Autorise le retour à la ligne */
  justify-content: center; /* Centre les membres horizontalement */
  gap: 30px; /* Espacement plus généreux entre les cartes */
  padding: 0 10px;
}

/* Section des contacts */
.team-member-contacts {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Icônes de contact */
.team-member-contacts a {
  text-decoration: none;
}

.team-member-contacts svg {
  width: 24px;
  height: 24px;
  transition: transform 0.2s;
}

.team-member-contacts svg:hover {
  transform: scale(1.1);
}

/* Adaptations pour les résolutions plus petites */
@media (max-width: 768px) {
  .team-member {
    flex: 1 1 calc(50% - 30px); /* 2 membres par ligne */
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 480px) {
  .team-member {
    flex: 1 1 100%; /* 1 membre par ligne */
    max-width: 100%;
  }
}