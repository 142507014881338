.ecosysteme-section {
  background-color: #f7f7f7;
  padding: 60px 10%;
  text-align: left;
  position: relative;
  overflow: hidden
}

#ecosysteme-entreprise h2, #ecosysteme-entreprise p{
text-align: center !important;
}

.eco-header h2 {
  color: #2e7d32; /* Vert plus subtil pour le titre */
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Ombre noire subtile */
}

.eco-header p {
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
}

.eco-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 90%;
  gap: 50px;
  flex-wrap: wrap;
}

.eco-partners {
  display: flex;
  justify-content: center;
  gap: 105px;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.eco-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 320px;  /* Augmenté la taille des cartes */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  padding: 30px;  /* Augmenté l'espace interne pour les cartes */
  border-radius: 15px;
  text-align: center;
  z-index: 2;
}

.eco-partner a{
  color: #333a3d 
}

.eco-before{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.1s ease, box-shadow 0.3s ease;
  perspective: 1000px;
}

.eco-before::before{
  content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 103%;
        height: 103%;
        z-index: 1;
        border-radius: 1rem;
        background: linear-gradient(to right bottom, #72c6ef, #004e8f);
        transform: rotate(4deg);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        transform-origin: 66% 30%;
        box-shadow: inset 0 1px 1px 0 hsla(0, 0%, 100%, .15), 0 50px 100px -20px rgba(50, 50, 93, .3), 0 30px 60px -30px rgba(0, 0, 0, .5), -10px 10px 60px -10px rgba(103, 178, 111, .3);
}

.eco-color-afges::before{
  background: linear-gradient(to right bottom, #72c6ef, #004e8f);
}

.eco-color-montaigne::before{
  background: linear-gradient(to right bottom, #c7ad94, #d4ac84);
}

.eco-color-expertus::before{
  background: linear-gradient(to right bottom, #aa646f, #a74354);
}

.eco-before:hover {
  transform: translateY(-5px) scale(1.05);
}

.eco-before:hover::before {
  transform: scale(1);
  width: 102.5%;
  height: 102.5%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.eco-partner img {
  width: 180px;  /* Agrandissement de l'image des logos */
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
}

.eco-partner p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  text-align: center;
}

.ecosysteme-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.1;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-inner.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  top: 0%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-front{
  margin-top: -10px;
}

.flip-card-back {
  transform: rotateY(180deg);
}



@media (max-width: 1024px) {
  .eco-header h2 {
    font-size: 2.5rem;  /* Titre légèrement plus petit sur tablettes */
  }

  .eco-header p {
    font-size: 1.1rem;
  }

  .eco-content {
    flex-direction: column;
    align-items: center;
  }

  .eco-partners {
    justify-content: center;
    gap: 50px;
  }

  .flip-card{
    width: 250px;
    height: 250px;
  }

  .eco-partner img {
    width: 160px;  /* Réduction de la taille des logos */
    height: 130px;
  }

  .eco-partner p {
    font-size: 0.95rem;
  }
}

@media (max-width: 704px) {
  #ecosysteme-entreprise{
    height: auto;
  }

  .eco-content{
    margin-top: 50px;
  }


  .eco-header h2 {
    font-size: 2.2rem;
  }

  .eco-header p {
    font-size: 1rem;
  }

  .eco-partner p {
    font-size: 0.85rem;
  }
}

@media (max-height: 950px) {
  #ecosysteme-entreprise {
    height: auto;
}

.eco-content{
  margin-block: 5%;
}

}