#values {
  padding: 30px 20px;
  text-align: center;
  padding-inline: 9%;
}

#values svg{
 min-width: 40px;
 min-height: 40px;
}

.values-content h2 {
  font-size: 2.5rem;
  color: #f9f9f9;
}

.values-grid {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 40px;
  justify-content: center;
}

/* Limite à trois éléments par ligne */
.value-card {
  background-color: #fff;
  padding: 20px;
  width: 310px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.value-icon {
  font-size: 2.5rem;
  color: #30dd58;
  margin-bottom: 15px;
}

.value-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.value-card p {
  color: #555;
  font-size: 1rem;
  line-height: 1.6;
}

@media (min-width: 1700px) {
  #values {
      padding-inline: 15%;
  }
}

@media (max-width: 1166px) {
  #values {
      height: auto;
  }
  .value-container{
    margin-block: 70px;
  }
}

@media (max-width: 776px) {
  .value-card {
    width: 100%; /* Passe à 1 colonne pour mobiles */
   
  }
}

@media (max-height: 950px) {
  #values {
    height: auto;
}

.value-container{
  margin-block: 80px;
}

}

.value-container{
    height: 95%;
    display: flex;
    align-items: center;
}


.title-icon-container{
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    align-items: center;
}