.solutions-container {
    display: flex;
    flex-wrap: wrap; /* Permet de passer à une nouvelle ligne si l'espace est insuffisant */
    justify-content: center; /* Centre les cartes horizontalement */
    gap: 20px; /* Ajoute un espace entre les cartes */
    padding: 20px;
  }

  #solutions h2{
    margin-bottom: 50px;
    color: #2e7d32;
}
  
  /* Pour les cartes */
  .service-card {
    flex: 1 1 calc(25% - 20px); /* Chaque carte occupe 25% de la largeur moins l'espace entre les cartes */
    max-width: 300px; /* Limite la largeur maximale */
    min-width: 250px; /* Largeur minimale pour un rendu correct */
  }
  
  /* Responsive pour les écrans moyens */
  @media (max-width: 1654px) {
    #solutions{
        height: auto;
    }
    .service-card {
      flex: 1 1 calc(50% - 20px); /* Chaque carte occupe 50% de la largeur pour les écrans moyens */
    }
  }
  
  /* Responsive pour les petits écrans */
  @media (max-width: 876px) {
    .service-card {
      flex: 1 1 100%; /* Chaque carte occupe toute la largeur pour les petits écrans */
    }
  }

  @media (max-height: 950px) {
    #solutions{
      height: auto;
    }

    .solutions-container{
      margin-block: 5%;
    }
  }