#about {
  padding: 80px 20px;
  background-color: #f7f7f7 !important;
  color: #333;
  display: flex;
  justify-content: center;
}

.about-content {
  max-width: 80%;
  margin: 0 12%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-header {
  text-align: center;
  margin-bottom: 30px;
}

.about-header h2 {
  color: #2e7d32 !important;
  margin-bottom: 20px;
  text-shadow: none !important;
}

.about-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #555;
  /* Texte plus clair pour la description */
}

.about-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

#about h3{
  text-align: left;
  font-size: 2rem;
  color: #2e7d32;
  margin-bottom: 20px;
}

.about-text h3 {
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
  /* Texte plus clair pour une lecture facile */
}

.about-image {
  display: flex;
  justify-content: center;
}

.about-image img {
  max-width: 400px;
  /* Ajusté pour une meilleure taille */
  border-radius: 8px;
  /* Coins arrondis pour un aspect plus doux */
}

.about-mission {
  text-align: center;
  margin-top: 20px;
}

.about-mission h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.about-mission p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
  /* Assurer la cohérence du texte */
}

#myVideo{
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 1030px) {
  #about {
    padding: 60px 10px;
    height: auto;
  }

  .about-header h2 {
    font-size: 2.2rem;
    text-align: center; /* Centrer pour améliorer la lisibilité */
  }

  #about h3 {
    text-align: center;
}

  .about-header p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }

  .about-details {
    flex-direction: column; /* Passez en colonne pour éviter l'encombrement */
    gap: 20px;
  }

  .about-image {
    justify-content: center;
    margin-bottom: 20px;
  }

  .about-image img,
  .about-image video {
    max-width: 100%; /* Assurez une largeur responsive */
    height: auto;
    border-radius: 6px; /* Coins légèrement arrondis */
  }

  .about-text h3 {
    font-size: 1.8rem;
    text-align: center; /* Uniformisez l'alignement */
  }

  .about-text p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }

  .about-mission h3 {
    font-size: 1.8rem;
    text-align: center;
  }

  .about-mission p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }
}

@media (max-height: 950px) {
  #about {
    height: auto;
  }
}
