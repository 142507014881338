.legal-mentions {
  max-width: 900px;
  margin: 0 auto;
  padding: 3rem;
  font-family: 'Arial', sans-serif;
  line-height: 1.7;
  color: #333;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.legal-mentions h1 {
  font-size: 2.8rem;
  margin-bottom: 2rem;
  color: #007B49;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.legal-mentions section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.legal-mentions h2 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 0.8rem;
  font-weight: 600;
  border-bottom: 2px solid #007B49;
  padding-bottom: 0.3rem;
}

.legal-mentions p {
  font-size: 1.1rem;
  margin-bottom: 1.2rem;
  color: #555;
  line-height: 1.6;
}

.legal-mentions a {
  color: #007B49;
  text-decoration: none;
  font-weight: 600;
}

.legal-mentions a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .legal-mentions {
      padding: 2rem;
  }

  .legal-mentions h1 {
      font-size: 2.4rem;
  }

  .legal-mentions h2 {
      font-size: 1.4rem;
  }

  .legal-mentions p {
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .legal-mentions {
      padding: 1.5rem;
  }

  .legal-mentions h1 {
      font-size: 2rem;
  }

  .legal-mentions h2 {
      font-size: 1.2rem;
  }

  .legal-mentions p {
      font-size: 0.95rem;
  }
}

.legal-mentions section{
  height: auto;
}