#intro-banner{
    height: 90vh;
}

.content-wrapper {
    position: relative;
    text-align: center;
    color: white;
    z-index: 1;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: center;
    gap: 40px;
  }
  
  /* Logo */
  .logo-container img {
    width: 250px;
    margin-bottom: 20px;
  }
  
  .logo-container-menu img {
    width: 100px;
  }

  .header-title {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  }