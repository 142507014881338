/* Image de fond */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

/* Navigation */
.navigation {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  position: relative;
  background-color: black;
  padding-inline: 2%;
  z-index: 300;
}

.nav-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 1.1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-list li a {
  text-decoration: none;
  color: white;

  text-transform: uppercase;
  transition: color 0.3s, transform 0.3s;
}

.nav-list li a:hover {
  color: #58d68d;
  transform: scale(1.1);
}

.container-nav{
  width: 100%;
}

/* Menu hamburger */
.hamburger {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
  color: #58d68d;
  font-size: 2rem;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  transition: transform 0.3s ease;
}

.hamburger-icon {
  transition: color 0.3s, transform 0.3s ease-in-out;
}

.hamburger:hover .hamburger-icon {
  color: #43c278;
  transform: scale(1.1);
}

.close-icon {
  transform: rotate(90deg);
}

/* Animation des lignes du hamburger lorsqu'il est actif */
.navigation.active .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.navigation.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.navigation.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}
/* Menu mobile actif */
.nav-list.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.85); /* Fond sombre mais légèrement transparent */
  padding: 20px;
  border-radius: 10px;
  gap: 15px;
  z-index: 3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Ombre subtile pour plus de contraste */
  opacity: 1;
  transform: translateY(0); /* Menu se déplace vers sa position d'origine */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Liens du menu */
.nav-list li a {
  text-decoration: none;
  color: #e0e0e0; /* Texte clair mais doux */
  text-transform: uppercase;
  transition: color 0.3s, transform 0.3s;
}

#contact-link > * {
  margin-bottom: 5px;
} 

/* Responsivité */
@media (max-width: 1480px) {
  .nav-list {
    display: none;
  }
  .nav-list.show{
    position: fixed;
  }
  .nav-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: 1.1rem;
    list-style: none;
    margin-bottom: 50px;
    padding: 0;
    opacity: 0;
    transform: translateY(-20px); /* Menu commence au-dessus */

  }

  .hamburger {
    display: flex;
    position: fixed;
  }
}

@media (max-width: 768px) {
  .header-title {
    font-size: 2.2rem;
  }

  .logo-container img {
    width: 150px;
  }
}